module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/codebuild/output/src124772296/src/gatsby-77p-v2/src/cms/cms.js","enableIdentityWidget":false,"htmlFavicon":"src/images/favicon-512x512.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.77plasticsurgery.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"77 Plastic Surgery","lang":"en","display":"standalone","short_name":"77 Plastic Surgery","description":"Led by plastic surgeon Dr. Larry Fan, 77 Plastic Surgery in San Francisco, CA, is the leading plastic surgery practice that focuses on cosmetic enhancements for natural results.","start_url":"/","background_color":"#eafcfb","theme_color":"#000","icon":"src/images/favicon-512x512.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"2db074d70dba104cf43bec4e1367824d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5D26B55X","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
